<template>
  <div>
    <span>{{ userData.data.customerInfo.username }}</span>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { format_number } from '@/views/global_function.js'
import {header_token} from '@/views/token.js'
import {taobaoService_dotnet} from '@/views/global.js'
import axios from "axios";
export default {
  name: 'Taobao2youText',

  components: { BLink },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      pointBalance: null
    };
  },

  created() {
    // this.getPointBalance()
  },

  methods: {
    format_number,
    async getPointBalance() {
      if (this.userData.role != 'admin') {
        var keepdata= []
        var axios = require('axios');
        var data = JSON.stringify({
          "customerID": JSON.parse(localStorage.getItem('cusId')),
        });

        var config = {
          method: 'post',
          url: taobaoService_dotnet + 'point/load-exchange-point-page',
          headers: {
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwIiwibmFtZSI6InRhb2JhbzJ5b3UiLCJyb2xlIjoiYWRtaW4iLCJjdXN0b21lcmlkIjoiIiwiZXhwIjoxNjI2MTAyNzgwLCJpc3MiOiJ0YW9iYW8yeW91IiwiYXVkIjoiaHR0cHM6Ly93d3cudGFvYmFvMnlvdS5jb20ifQ.gIhTvIMLXxpq6bUFPbp242szDgPRl33SaF3k5f6T3-U', 
            'Content-Type': 'application/json'
          },
          data : data
        };

        await axios(config)
        .then(function (response) {
          keepdata = response.data
        })
        .catch(function (error) {
          console.log(error);
        });
        this.pointBalance = keepdata.pointBalance
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>