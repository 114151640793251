<template>
  <div>
    <b-button class="btn-icon" variant="flat-secondary" @click="toAnotherPath">
      <feather-icon
        :badge="itemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { taobaoService_dotnet   } from '@/views/global'
import axios from 'axios'
import { token_api, header_token } from '@/views/token'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      itemsCount: 0
    }
  },

  created() {
    // this.getItemCount()
  },
  methods: {
    async getItemCount() {
      let response = await axios.post(
        taobaoService_dotnet + 'app/Cart/load-cart-page',
        { "customerID": JSON.parse(localStorage.getItem('cusId')) },
        { headers: header_token }
      );
      this.itemsCount = response.data.quantityCart
    },
    async toAnotherPath() {
      const pathname = window.location.pathname;
      if (pathname == '/customer-orders/cart') {
        await this.$router.push({path: '/'})
        this.$router.push({path: '/customer-orders/cart'})
      } else {
        this.$router.push({path: '/customer-orders/cart'})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
