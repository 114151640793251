<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              href="https://jjcargothailand.com"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <h2 class="brand-text">
                {{ appName }}
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />

      <!-- balance -->
      <div class="pb-1 d-md-none">
        <h5 class="text-center">ยอดเงินใน Balance</h5>
        <h5 class="pl-2">ยอดเงินบาท <span :class="this.baht >= 0 ? 'text-success' : 'text-danger'">{{format_price(this.baht)}}</span> บาท </h5>
        <h5 class="pl-2">ยอดเงินหยวน <span :class="this.yuan >= 0 ? 'text-success' : 'text-danger'">{{format_price(this.yuan)}}</span> หยวน </h5>
      </div>

      <!-- rate -->
      <!-- <div class="d-flex d-none d-md-flex d-lg-none justify-content-center pb-2">
        <div>
          <h5 >เรทฝากสั่ง</h5>
          <h5 class="pl-1">{{this.rate.sellRate}}</h5>
        </div>
        <div class="vl ml-1">
          <div class="pl-1">
            <h5 >เรทฝากจ่าย</h5>
            <h5 class="pl-1">{{this.rate.topUpRate}}</h5>
          </div>
        </div>
        <div class="vl ml-1">
          <div class="pl-1">
            <h5 >เรทโอนธนาคาร/Alipay</h5>
            <h5 class="pl-1">{{this.rate.transferRate}}</h5>
            <small>(ขั้นต่ำ 5000 หยวน ก่อนโอนติดต่อผ่านทางไลน์เท่านั้น)</small>
          </div>
        </div>
      </div> -->
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg, BContainer } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import {header_token} from '@/views/token.js'
import {jjCargoService_dotnet} from '@/views/global.js'
import axios from "axios";
import { format_price } from '@/views/global_function.js'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    BContainer,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      // balance
      baht: null,
      yuan: null,

      // rate
      rate: [],
    };
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },

  watch:{
    async $route (to, from){
      this.getBalance()
    }
  },

  async created() {
    this.getBalance()
  },

  methods: {
    format_price,
    async getBalance () {
      let response = await axios.post(
        jjCargoService_dotnet  + 'Balance/get-aprrove-balance',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId'))
        },
        { headers: header_token }
      )
      this.baht = response.data.approvedBalance
      this.yuan = response.data.approvedYuanBalance
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
<style >
  .vl {
    border-left: 1px solid ;
    height: 55px;
  }
</style>
