<template>
  <div>
    <span>มียอดเงินใน Balance <span :class="this.baht >= 0 ? 'text-success' : 'text-danger'">{{format_price(this.baht)}}</span> บาท </span>
    <br>
    <span>มียอดเงินหยวนใน Balance <span :class="this.yuan >= 0 ? 'text-success' : 'text-danger'">{{format_price(this.yuan)}}</span> หยวน </span>
  </div>
</template>

<script>
import { format_price } from '@/views/global_function.js'
import { BLink } from 'bootstrap-vue'
import {header_token} from '@/views/token.js'
import {jjCargoService_dotnet} from '@/views/global.js'
import axios from "axios";

export default {
  name: 'JJCargoFrontendBalance',

  components: { BLink },

  data() {
    return {
      baht: null,
      yuan: null,
    };
  },

  watch:{
    async $route (to, from){
      this.getBalance()
    }
  },

  async created() {
    this.getBalance()
  },

  methods: {
    format_price,
    async getBalance () {
      let response = await axios.post(
        jjCargoService_dotnet  + 'Balance/get-aprrove-balance',
        {
          "customerID": JSON.parse(localStorage.getItem('cusId'))
        },
        { headers: header_token }
      )
      this.baht = response.data.approvedBalance
      this.yuan = response.data.approvedYuanBalance
    },
  },
};
</script>

<style>
.vl {
  border-left: 1px solid ;
  height: 500px;
}
</style>