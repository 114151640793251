export default [
    // {
    //   header: '—— การเงิน ——',
    //   resource: 'normal',
    //   action: 'manage',
    // },
    {
      title: 'รายการ Balance',
      route: 'finance-balance',
      icon: 'BriefcaseIcon',
      resource: 'normal',
      action: 'manage',
    },

  ]