<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none" v-if="userData.role == 'admin'">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-md-flex" v-if="userData.role == 'admin'">

      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
      <show-balance/>
      <!-- <div class="d-none d-lg-flex">
        <div class="vl ml-1">
          <div class="pl-1">
            <h5 >เรทฝากสั่ง</h5>
            <h5 class="pl-1">{{this.rate.sellRate}}</h5>
          </div>
        </div>
        <div class="vl ml-1">
          <div class="pl-1">
            <h5 >เรทฝากจ่าย</h5>
            <h5 class="pl-1">{{this.rate.topUpRate}}</h5>
          </div>
        </div>
         <div class="vl ml-1">
          <div class="pl-1">
            <h5 >เรทโอนธนาคาร/Alipay</h5>
            <h5 class="pl-1">{{this.rate.transferRate}}</h5>
            <small>(ขั้นต่ำ 5000 หยวน ก่อนโอนติดต่อผ่านทางไลน์เท่านั้น)</small>
          </div>
        </div>
      </div> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <show-text />
      <!-- <locale /> -->
      <dark-Toggler class="d-none d-lg-block" v-if="userData.role == 'admin'"/>
      <!-- <search-bar /> -->
      <!-- <coupon v-if="userData.role == 'admin'"/>
      <cart-dropdown v-if="userData.role == 'admin'"/> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import ShowBalance from './components/ShowBalance.vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import ShowText from './components/Text.vue'
import Coupon from './components/Coupon.vue'
import {header_token} from '@/views/token.js'
import {taobaoService_dotnet} from '@/views/global.js'
import axios from "axios";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    ShowText,
    ShowBalance,
    Coupon
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    };
  },

  methods: {

  },
}
</script>
<style >
  .vl {
    border-left: 1px solid ;
    height: 55px;
  }
</style>
