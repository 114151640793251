<template>
	<div>
		<b-button class="btn-icon" variant="flat-secondary" :to="{ name: 'me-coupon'}">
      <!-- <feather-icon
        :badge="$store.state['app-ecommerce'].cartItemsCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      /> -->
			<feather-icon
        class="text-body"
        icon="GiftIcon"
        size="21"
      />
    </b-button>
	</div>
</template>

<script>
import { BButton, BBadge } from 'bootstrap-vue'
import {header_token} from '@/views/token.js'
import {taobaoService_dotnet} from '@/views/global.js'
import axios from "axios";
export default {
	name: 'Taobao2youCoupon',

	components: { BButton, BBadge },
};
</script>

<style lang="scss" scoped>

</style>