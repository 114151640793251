export default [
    // {
    //   header: '—— การจัดส่ง ——',
    //   resource: 'normal',
    //   action: 'manage',
    // },
    {
      title: 'เช็คสถานะ',
      route: 'product-status',
      icon: 'MapPinIcon',
      resource: 'normal',
      action: 'manage',
    },

  ]